import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Person from "../components/person"


const Leitung = ({data}) => {

  const personData = {
    "Beatrice": {"name": "Beatrice Nufer", "position": "Heimleitung", "tel": "071 622 14 11", "email": "b.nufer@bannau.ch"},
    "Sandra": {"name": "Sandra Alakmeh", "position": "Leitung Pflege & Betreuung", "tel": "071 622 14 11", "email": "s.alakmeh@bannau.ch"},
    "Martin": {"name": "Martin Knöpfli", "position": "Leitung Küche", "tel": "071 622 14 11", "email": "m.knoepfli@bannau.ch"},
    "Susanne": {"name": "Susanne Büchler", "position": "Leitung Hauswirtschaft", "tel": "071 622 14 11", "email": "s.buechler@bannau.ch"}
  }
	const images = data.teamImages.edges

  return (
    <Layout title="Leitung" slogan="Gemeinsam für Sie" headerImage={data.headerImage.childImageSharp.fluid} paddingTop="20px">
			<div className="person-grid">
      {images.map((element) => (
				<>
				<Person name={personData[element.node.name].name} 
          image={element.node.childImageSharp.fluid}
          position={personData[element.node.name].position}
          tel={personData[element.node.name].tel}
          email={personData[element.node.name].email}
        />
				</>
      ))}
			</div>
    </Layout>
  ) 
}

export default Leitung
export const pageQuery = graphql`
  query {
    teamImages: allFile(filter: {relativeDirectory: {eq: "leitung"}}, sort: {order: ASC, fields: changeTime}) {
			edges {
				node {
					childImageSharp {
						fluid(quality: 90, maxWidth: 800) {
							...GatsbyImageSharpFluid_withWebp
						}
					},
					name
				}
			}
		}
    headerImage: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-misc-eingang.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
  }
`