import React from "react"
import Image from "gatsby-image"


const Person = (props) => {
  return (
    <div className="person">
      {props.image != null && 
        <Image className='cardWithImage__img' fluid={props.image} />
      }
      <div className={props.position != null ? 'person__info person__info--box' : 'person__info'}>
        <h4>{props.name}</h4>
        {props.position != null && <h5>{props.position}</h5> }
        {props.tel != null && <p>Tel.: {props.tel}</p> }
        {props.email != null && <p>Mail: <a href={props.email}>{props.email}</a></p> }
      </div>
    </div>
  )
}

export default Person
